@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap");
.hero {
	width: 100%;
	/* max-width: 1350px; */
	margin: 0 auto;
	position: relative;
	/* padding-top: 3rem; */
}

.hero .hero-image-container {
	position: relative;
	width: 100%;
}

.hero .hero-image {
	/* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../../image/vitalRegistration.png"); */
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: auto;
	position: relative;
	min-height: 240px;
	max-height: 400px;
	background: center center #fff;
	background-repeat: no-repeat;
	background-size: contain;
	overflow: hidden;
	margin: 0;
	/* transform: scale(0.8); */
}

.hero .hero-image:after {
	content: "";
	display: block;
	padding-bottom: 56.25%;
}
.divider-vital {
	background: url(http://cdn.agilitycms.com/polaroid/landing-pages/holiday-guide-2017/divider-2.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 50px;
	width: 100%;
	display: block;
	max-width: 800px;
	margin: 0 auto;
}

.button-action {
	margin-top: 10px;
	font-weight: 500;
	font-size: 20px;
	color: #646363;
	text-decoration: none;
	font-family: "UniversLTPro-55Roman", arial, helvetica, sans-serif;
	cursor: pointer;
}

.button-action:hover {
	color: #777;
}
